<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card
          class="mx-auto"
          max-width="400"
        >
          <v-img
            v-if="hasImages"
            class="align-end grey lighten-1"
            height="200"
            :src="getImage"
          />

          <v-card-title class="headline">
            {{ booking.customer ? booking.customer.address.name_line : null }}
          </v-card-title>
          <v-card-subtitle class="pb-0">
            {{ $t('phone') }}: {{ booking.customer ? booking.customer.phone : null }}
          </v-card-subtitle>
          <v-card-subtitle class="pt-0 pb-5">
            {{ $t('email') }}: {{ booking.customer ? booking.customer.email : null }}
          </v-card-subtitle>

          <v-card-subtitle class="pt-0">
            <v-btn
              v-if="!booking.status || booking.status === '0'"
              x-small
              color="error"
            >
              {{ $t('cancelled') }} 
            </v-btn>
            <v-btn
              v-else
              x-small
              color="success"
            >
              {{ $t('active') }}  
            </v-btn>
          </v-card-subtitle>
          <v-card-text>
            <div class="text--secondary">
              {{ $t('booked') }}
            </div>
            <div class="body-1 text--primary">
              <div>{{ booking.item ? booking.item.title : null }}</div>
              <div v-if="booking.startsAtTime">
                {{ dateParse(booking.startsAtTime).toDateString() }}
              </div>
              <div>
                <span v-if="booking.startsAtTime">{{ dateParse(booking.startsAtTime).toTimeString().slice(0,5) }}</span>
                <span v-if="booking.endsAtTime"> - {{ dateParse(booking.endsAtTime).toTimeString().slice(0,5) }}</span>
              </div>
            </div>
          </v-card-text>
          <v-card-text>
            <v-expansion-panels class="elevation-1">
              <v-expansion-panel>
                <v-expansion-panel-header>{{ $t('additionalInfo') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    <div class="text--secondary">
                      {{ $t('bookingId') }} 
                    </div>
                    <div>{{ booking.uuid }}</div>
                  </div>
                  <div class="py-3">
                    <div class="text--secondary">
                      {{ $t('bookingCreated') }}
                    </div>
                    <div>{{ dateParse(booking.created).toISOString().replace('T', ' ').substr(0, 19) }}</div>
                  </div>
                  <div>
                    <div class="text--secondary">
                      {{ $t('bookingLastUpdated') }}
                    </div>
                    <div>{{ dateParse(booking.changed).toISOString().replace('T', ' ').substr(0, 19) }}</div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-card-actions>
            <v-container class="py-0">
              <v-row align="center">
                <v-col class="pt-0">
                  <v-btn
                    large
                    block
                    color="primary"
                    @click="editBooking"
                    :disabled="!booking.status||booking.status ==='0'"
                  >
                    {{ $t('edit') }}
                  </v-btn>
                </v-col>
                <v-col class="pt-0">
                  <v-btn
                    large
                    block
                    color="error"
                    @click="clickCancel"
                    :disabled="!booking.status||booking.status ==='0'"
                  >
                    {{ $t('cancelBooking') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col />
    </v-row>
    <BookingDialog
      v-if="modal"
      v-model="modal"
      type="Edit"
      :booking="booking"
    />
    <v-dialog
      v-if="cancelBookingAction"
      v-model="cancelBookingAction"
      max-width="800"
    >
      <v-card>
        <v-card-title>
          {{ $t('cancelBooking?') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('areYouSureCancelBooking?') }}
        </v-card-subtitle>
        <v-card-subtitle
          v-if="cancelError"
          class="error--text pb-0"
        >
          {{ cancelError }}
        </v-card-subtitle>
        <v-card-actions>
          <v-row
            dense
            justify="end"
          >
            <v-col
              cols="12"
              sm="auto"
            >
              <v-btn
                color="error"
                block
                large
                @click="clickCancel"
                :disabled="processing"
              >
                {{ $t('goBack') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="auto"
            >
              <v-btn
                color="primary"
                block
                large
                @click="cancelBookingMethod"
                :loading="processing"
                :disabled="processing"
              >
                {{ $t('confirm') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
	import BookingDialog from "@/components/BookingDialog"

	/**
	 * @property {String} startsAtTime
	 * @property {String} endsAtTime
	 */
	export default {
		name: "Booking",
		components: {
      BookingDialog
    },
		data() {
			return {
				cancelBookingAction: false,
				cancelError: null,
				processing: false,
				booking: {
					customer: {
						address: {
							name_line: null
						},
						phone: null,
						email: null,
					}
				},
				modal: false
			}
		},
		beforeMount() {
			this.booking = this.$store.getters.getBookingByUUID(this.$route.params.uuid)
		},
		mounted() {
			//console.log(this.booking)
		},
		computed: {
			// bookingFromStore() {
			// 	return this.$store.getters.getBookingByUUID(this.$route.params.uuid)
			// },
			hasImages() {
				return !!(this.booking.item && this.booking.item.images && this.booking.item.images.length)
			},
			getImage() {
				if(this.hasImages
					&& this.booking.item.images[0].file
					&& this.booking.item.images[0].file.sources
					&& this.booking.item.images[0].file.sources.original) {
					return this.booking.item.images[0].file.sources.original
				} else {
					return null
				}
			}
		},
		methods: {
			clickCancel() {
				this.cancelBookingAction = !this.cancelBookingAction
				this.cancelError = null
			},
			cancelBookingMethod() {
				this.processing = true

				this.cancelError = null

				if(!this.booking.item || !this.booking.item.uuid || !this.booking.customer || !this.booking.customer.uuid) {
					this.cancelError = this.$t('unableToCancelBookingDueToMissingIdentifiers')
					return
				}

				const b = {
					uuid: this.booking.uuid,
					item: this.booking.item.uuid,
					customer: this.booking.customer.uuid,
					namespace: this.booking.namespace,
					status: false
				}

				this.$store.dispatch('updateBooking', b)
					.then(result => {
						if(result) {
							this.cancelBookingAction = false
              this.$store.commit('updateActionSuccess', {
                message: this.$t('success'),
                subMessage: this.$t('bookingHasBeenCancelled')
              })
						} else {
							this.cancelError = this.$t('couldNotCancelBooking')
						}
					})
					.finally(() => {
						this.booking = this.$store.getters.getBookingByUUID(this.$route.params.uuid)
						this.processing = false
					})
			},
			editBooking() {
				this.modal = true
			},
			dateParse(secondString) {
				if(secondString.toString().length === 10) {
					return new Date(secondString * 1000)
				} else {
					return new Date(secondString)
				}
			}
		}
	}
</script>

<style scoped>

</style>